import { render, staticRenderFns } from "./VideosOfTalent.vue?vue&type=template&id=fe4cb7d4&scoped=true&"
import script from "./VideosOfTalent.vue?vue&type=script&lang=ts&"
export * from "./VideosOfTalent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4cb7d4",
  null
  
)

export default component.exports